import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  login as loginApi,
  loginMFA as loginMFAApi,
} from '@shared/api/authentication';
import Login from '@shared/components/Login/PasswordLogin';
import { LOGIN_ERROR_MESSAGE as ERROR_MESSAGE } from '@shared/constants';
import { useApi } from '@shared/containers/hooks/api';
import {
  ApiState,
  TransformedApiError,
} from '@shared/containers/hooks/api/types';
import { isTransformedApiError } from '@shared/containers/hooks/api/utils';
import { RouteComponentProps, StrictOmit } from '@shared/types';
import { useNavigate } from '@shared/utils/route';
import { isAtLeast, isRequired } from '@shared/utils/validators';
import {
  createValidator,
  validateAll,
} from '@shared/utils/validators/validator-model';

import { ManagerPortalUser } from '@ManagerPortal/types';

import {
  getContextAndTargetPath,
  handleRedirectOfTargetUri,
  separateAliasFromPath,
} from '../utils';

export const VALIDATOR_MESSAGE = {
  EMAIL_REQUIRED: 'A login email is required!',
  PASSWORD_REQUIRED: 'You need to enter a password',
  PASSWORD_MIN_LENGTH: 'Password needs to be at least 4 characters',
} as const;

const validators = {
  email: [createValidator(isRequired, VALIDATOR_MESSAGE.EMAIL_REQUIRED)],
  password: [
    createValidator(isRequired, VALIDATOR_MESSAGE.PASSWORD_REQUIRED),
    createValidator(isAtLeast(4), VALIDATOR_MESSAGE.PASSWORD_MIN_LENGTH),
  ],
};

type ErrorMessage = (typeof ERROR_MESSAGE)[keyof typeof ERROR_MESSAGE];

type PasswordLoginProps = RouteComponentProps & {
  onManagerPortalLogin: (userAndConfig: ManagerPortalUser) => void;
  onStaffPortalLogin: (error?: TransformedApiError) => void;
  onResetLoginProviders: () => void;
  email: string;
  region?: string;
  isMFA?: boolean;
  domainGroupId?: string;
};

interface FormStateType {
  password: string;
  areUserCredentialsExpired: boolean;
  passwordSecurityLevel: string | number;
  passwordResetToken: null | string;
  phoneNumber: null | string;
}

// TODO: This component was refactored into TS, but is still in need of some real refactoring and love :))
const PasswordLogin = ({
  onManagerPortalLogin,
  onStaffPortalLogin,
  location,
  onResetLoginProviders,
  email = '',
  region,
  isMFA = false,
  domainGroupId,
}: PasswordLoginProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const { customerAlias } = separateAliasFromPath();
    if (!email) navigate(customerAlias ? `/${customerAlias}` : '/');
  }, [email, navigate]);

  const [{ isLoading: isLoadingManagerPortal, ...loginStatus }, login] = useApi(
    isMFA ? loginMFAApi : loginApi,
  );

  const [showValidations, setShowValidations] = useState(false);

  const [formState, setFormState] = useState<FormStateType>({
    password: '',
    areUserCredentialsExpired: false,
    passwordSecurityLevel: 0,
    passwordResetToken: null,
    phoneNumber: null,
  });

  const { password, phoneNumber } = formState;
  const emailError = validateAll(validators.email, email);
  const passwordError = validateAll(validators.password, password);

  const contextAndPath = getContextAndTargetPath();
  const createURLWithTargetPath = (url: string) =>
    `${url}?targetPath=${contextAndPath.targetPath}&context=${contextAndPath.targetPath}`;

  const resetPasswordUrl = createURLWithTargetPath('/resetpassword');

  const handleLoginError = (error: unknown) => {
    if (!isTransformedApiError(error)) throw error;
    if (!Array.isArray(error.originalError.body)) {
      onStaffPortalLogin(error);

      if (
        error.originalError.body?.message ===
        ERROR_MESSAGE.EXPIRED_CREDENTIALS_BACKEND
      ) {
        setFormState({
          ...formState,
          areUserCredentialsExpired: true,
          passwordSecurityLevel:
            error.originalError.response.headers['password-security-level'] ??
            '',
          passwordResetToken:
            error.originalError.response.headers['password-reset-token'] ?? '',
        });
      }
    }

    return error;
  };

  const initializeAndRedirect = (userAndConfig: ManagerPortalUser) => {
    if (!userAndConfig.targetUri.includes('https://')) {
      onManagerPortalLogin(userAndConfig);
      onStaffPortalLogin();
    }
    navigate(handleRedirectOfTargetUri(userAndConfig.targetUri));
  };

  const handleSubmit = async (e: SubmitEvent) => {
    e.preventDefault();
    setShowValidations(true);

    if (emailError || passwordError) return;

    try {
      const { data: userAndConfig } = await login(
        email,
        password,
        domainGroupId,
        contextAndPath.targetPath,
        contextAndPath.context,
        phoneNumber,
      );

      if (isMFA) {
        const targetUri = String(userAndConfig?.targetUri) || '/';
        const redirectMFAURL = targetUri.includes('authcode')
          ? createURLWithTargetPath(`${location?.pathname}${targetUri}`)
          : handleRedirectOfTargetUri(targetUri);
        navigate(redirectMFAURL);
        return navigate(0);
      }
      initializeAndRedirect(userAndConfig);
    } catch (error) {
      handleLoginError(error);
    }
  };

  const getErrorMessage = ({
    isError,
    originalError,
  }: StrictOmit<ApiState<unknown>, 'isLoading'>) => {
    if (originalError) {
      const { body, status: statusCode } = originalError;
      let { response: { body: { message } = {} } = {} } = originalError;

      const { message: originalErrorMessage } =
        (!Array.isArray(body) && body) || {};

      if (!isError) return {};

      let errorMessage: ErrorMessage | null = null;
      if (statusCode === 412) {
        errorMessage = ERROR_MESSAGE.SSO;
      }
      if (statusCode === 422) {
        message = ERROR_MESSAGE.NO_PHONE_NUMBER;
      }

      if (originalErrorMessage === ERROR_MESSAGE.WRONG_CREDENTIALS_BACKEND) {
        errorMessage = ERROR_MESSAGE.WRONG_CREDENTIALS;
      }
      if (originalErrorMessage === ERROR_MESSAGE.ACCOUNT_LOCKED_BACKEND) {
        errorMessage = ERROR_MESSAGE.ACCOUNT_LOCKED;
      }

      errorMessage = isMFA ? message : errorMessage;

      return {
        errorMessage: errorMessage ?? ERROR_MESSAGE.DEFAULT,
      };
    }
    return {};
  };

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    setFormState({ ...formState, [name]: value });

  const { errorMessage } = getErrorMessage(loginStatus);

  const errorsFeedbackOpen = !!(
    !formState.areUserCredentialsExpired && errorMessage
  );
  const { areUserCredentialsExpired } = formState;

  return (
    <Login
      email={email}
      region={region}
      password={password}
      phoneNumber={phoneNumber}
      resetPasswordUrl={resetPasswordUrl}
      passwordSecurityLevel={formState.passwordSecurityLevel}
      passwordResetToken={formState.passwordResetToken}
      errors={{
        emailError,
        passwordError,
        errorsFeedbackOpen,
        errorsFeedbackMessage: errorMessage,
        areUserCredentialsExpired,
        showValidations,
      }}
      isLoading={isLoadingManagerPortal}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onResetLoginProviders={onResetLoginProviders}
      domainGroupId={domainGroupId}
    />
  );
};

export default PasswordLogin;
