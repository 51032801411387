import { createContext } from 'react';

import { ApiState } from '@shared/containers/hooks/api/types';
import { Group, ShiftType } from '@shared/types';
import { ParsedQuery } from '@shared/utils/route';

import useAbsences from '@ManagerPortal/containers/Schedule/Data/useAbsences';
import useLeaveRequests from '@ManagerPortal/containers/Schedule/Data/useLeaveRequests';
import { usePunches } from '@ManagerPortal/containers/Schedule/Data/usePunches';
import useShifts from '@ManagerPortal/containers/Schedule/Data/useShifts';
import { TimeParameters } from '@ManagerPortal/containers/Schedule/Main/autoApiTypes';
import {
  TAbsenceProps,
  TAvailabilityProps,
  TPunchProps,
  TShiftBaseScheduleProps,
  TShiftModifyTimeOptions,
  TShiftMoveOptions,
  TShiftProps,
} from '@ManagerPortal/containers/Schedule/Main/SchedulerActionsProvider/types';
import { TLeaveRequest } from '@ManagerPortal/containers/Schedule/Main/ShiftControlCentre/LeaveRequests/types';
import {
  AbsenceItem,
  ApiStateWithIndexedData,
  AvailabilityItem,
  PunchItem,
  ScheduleRow,
  ShiftItem,
  ShiftItemComplete,
  UnavailabilityItem,
  WorkerItem,
} from '@ManagerPortal/containers/Schedule/Main/types';
import {
  ILanguageContext,
  ManagerPortalUser,
  UserSettings,
} from '@ManagerPortal/types';
import { MealBreakSettings } from '@ManagerPortal/types/settings/mealBreaks';

import { GroupSettings } from './types';

export const LanguageContext = createContext<ILanguageContext>({
  language: 'en',
  dictionary: {},
  defaultDictionary: {},
});

export const UserContext = createContext<{
  user: ManagerPortalUser;
  receiveUser: () => void;
}>({
  user: {} as ManagerPortalUser,
  receiveUser: () => {},
});

export const UserSettingsContext = createContext<UserSettings | null>(null);

export const GroupSettingsContext = createContext<GroupSettings>({});

export const MuteSettingsContext = createContext(null);

export const MealBreaksContext =
  createContext<ApiState<MealBreakSettings> | null>(null);

export const AccountContext = createContext<{
  currentAccountId?: number;
  currentGroupId?: number;
  groups?: { [id: string]: Group };
  mainUnitGroupId?: number;
}>({});

export const AccountSettingsContext = createContext({});

export const ClipboardModeSubTypes = {
  DurationStart: 'duration-start',
  DurationEnd: 'duration-end',
  Copy: 'copy',
  Move: 'move',
} as const;

export type ClipboardModeSubType =
  (typeof ClipboardModeSubTypes)[keyof typeof ClipboardModeSubTypes];

export const ClipboardModeViaTypes = {
  Drag: 'drag',
  Panel: 'pick',
  ShiftPanel: 'shift_panel',
  None: 'none',
} as const;

export type ClipboardModeViaType =
  (typeof ClipboardModeViaTypes)[keyof typeof ClipboardModeViaTypes];

export type TClipboardModeType = {
  via: ClipboardModeViaType;
  sub?: ClipboardModeSubType;
};

export enum CLIPBOARD_ITEM_TYPE {
  NONE = 'none',
  SHIFT = 'shift',
  BASE_SHIFT = 'baseShift',
  SHIFT_ARR = 'shiftArr',
}

type TClipboardBaseScheduleShiftType = {
  type: CLIPBOARD_ITEM_TYPE.BASE_SHIFT;
  item: TShiftBaseScheduleProps;
  mode: TClipboardModeType;
};

type TClipboardShiftType = {
  type: CLIPBOARD_ITEM_TYPE.SHIFT;
  item: ShiftItemComplete;
  mode: TClipboardModeType;
};

type TClipboardNoneType = {
  type: CLIPBOARD_ITEM_TYPE.NONE;
  item: null;
  mode: TClipboardModeType;
};

type TClipboardBulkShiftType = {
  type: CLIPBOARD_ITEM_TYPE.SHIFT_ARR;
  item: ShiftItemComplete[];
  mode: TClipboardModeType;
};

export type TClipboardContents =
  | TClipboardBulkShiftType
  | TClipboardShiftType
  | TClipboardBaseScheduleShiftType
  | TClipboardNoneType;

export type TScheduleRowsContextData = {
  scheduleRows: {
    scheduleData: ScheduleRow[];
    timeperiod?: string;
  };
  punchesMetadata: unknown;
  absencesMetadata: unknown;
  // TODO: Properly type these when transitioning
  // data setting in ScheduleRowsProvider functions to TS
  setSortedScheduleItems: (items: {
    scheduleRows: { scheduleData: ScheduleRow[] };
    punchesMetadata: any;
    absencesMetadata: any;
  }) => void;
  features: any;
  employees: ApiStateWithIndexedData<WorkerItem> | undefined;
  shifts:
    | (ReturnType<typeof useShifts> & {
        data: Record<number, ShiftItem>;
      })
    | undefined;
  punches:
    | (ReturnType<typeof usePunches> & {
        data: Record<number, PunchItem>;
      })
    | undefined;
  leaveRequests:
    | (ReturnType<typeof useLeaveRequests> & {
        data: Record<number, TLeaveRequest> | null;
      })
    | undefined;
  absences:
    | (ReturnType<typeof useAbsences> & {
        data: Record<number, AbsenceItem> | null;
      })
    | undefined;
  unavailabilities: ApiState<UnavailabilityItem[]> | undefined;
  salaryWarnings: any;
  isLoadingSchedule: boolean | undefined;
  shiftTypes: Record<number, ShiftType> | undefined;
  employeeHours: any;
  refetchScheduledHours: () => void;
  availabilities: ApiState<AvailabilityItem[]> | undefined;
};

export type TScheduleRowsContextType = TScheduleRowsContextData & {
  setSortedScheduleItems: (data: TScheduleRowsContextData) => void;
};

type TSchedulerDisplayStateContext = {
  isUnassignedRowVisible: boolean;
  toggleIsUnassignedRowVisible: () => void;
};

export const ClipboardContext = createContext<{
  clipboard: TClipboardContents;
  setClipboard: (clipboard: TClipboardContents) => void;
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
  clear: () => void;
  setShiftById: (
    shiftId: number | string,
    shift: TShiftProps,
    mode?: TClipboardModeType,
  ) => void;
}>({
  clipboard: {
    item: null,
    mode: { via: ClipboardModeViaTypes.None },
    type: CLIPBOARD_ITEM_TYPE.NONE,
  },
  setClipboard: () => {},
  isLoading: false,
  setLoading: () => {},
  clear: () => {},
  setShiftById: () => {},
});

type TDestination = {
  employeeId: number | null;
  beginDay?: number;
  date: string;
};

export const BaseScheduleActionsContext = createContext<{
  onShiftCopy: (
    source: TShiftBaseScheduleProps,
    destination: TDestination,
  ) => void;
  onShiftMove: (
    source: TShiftBaseScheduleProps,
    destination: TDestination,
  ) => void;
  onShiftDelete: (shift: { id: number }) => void;
}>({
  onShiftCopy: () => {},
  onShiftMove: () => {},
  onShiftDelete: () => {},
});

// TODO - fix any
interface IGroupData {
  lockedDate: ApiState<any>;
  timeParameters: ApiState<[TimeParameters]>;
  skills: ApiStateWithIndexedData<any>;
  absenceSchedules: ApiState<any>;
  sections: ApiStateWithIndexedData<any>;
  shiftTypes: ApiStateWithIndexedData<any>;
  costCentres: ApiStateWithIndexedData<any>;
  staffCategories: ApiStateWithIndexedData<any>;
  absenceReasons: ApiStateWithIndexedData<any>;
  projects: ApiStateWithIndexedData<any>;
  timeTrackers: ApiState<any>;
}

export const GroupDataContext = createContext<
  IGroupData | Record<string, never>
>({});

export const ScheduleRowsContext = createContext<TScheduleRowsContextType>({
  scheduleRows: { scheduleData: [] },
  punchesMetadata: {},
  absencesMetadata: {},
  setSortedScheduleItems: () => {},
  absences: undefined,
  unavailabilities: undefined,
  leaveRequests: undefined,
  employees: undefined,
  shifts: undefined,
  punches: undefined,
  salaryWarnings: undefined,
  availabilities: undefined,
  features: undefined,
  isLoadingSchedule: undefined,
  shiftTypes: undefined,
  employeeHours: undefined,
  refetchScheduledHours: () => {},
});

type TScheduleActionsContext = {
  onShiftCopy: (sourceShift: TShiftProps, options: TShiftMoveOptions) => void;
  onShiftTimeCopy: (
    sourceShift: TShiftProps,
    options: TShiftModifyTimeOptions,
  ) => void;
  onShiftMove: (sourceShift: TShiftProps, options: TShiftMoveOptions) => void;
  onShiftTimeMove: (
    sourceShift: TShiftProps,
    options: TShiftModifyTimeOptions,
  ) => void;
  onShiftDelete: (shift: TShiftProps) => void;
  onAddPunchFromShift: () => void;
  onAbsenceDelete: (absence: TAbsenceProps) => void;
  onPunchDelete: (punch: TPunchProps) => void;
  onAvailabilityDelete: (availability: TAvailabilityProps) => void;
};

export const ScheduleActionsContext = createContext<TScheduleActionsContext>({
  onShiftCopy: () => {},
  onShiftTimeCopy: () => {},
  onShiftMove: () => {},
  onShiftTimeMove: () => {},
  onShiftDelete: () => {},
  onAddPunchFromShift: () => {},
  onAbsenceDelete: () => {},
  onPunchDelete: () => {},
  onAvailabilityDelete: () => {},
});

export const SchedulerDisplayStateContext =
  createContext<TSchedulerDisplayStateContext>({
    isUnassignedRowVisible: true,
    toggleIsUnassignedRowVisible: () => {},
  });

export const MessagesContext = createContext({
  onToggle: () => {},
  onClose: () => {},
  open: false,
  openNew: false,
  onOpenNew: () => {},
  onCloseNew: () => {},
  count: null,
});

type TSalaryWarningsContext = ApiState<{
  shiftWarnings: Record<string | number, any>;
  punchWarnings: object;
  absenceWarnings: never[];
  absenceWarningsObj: object;
}>;

export const SalaryWarningsContext = createContext<
  TSalaryWarningsContext | Record<string, never>
>({});

export const TimecardTableContext = createContext({});

export interface ILocationContextValue {
  isBaseSchedule: boolean;
  isDashboard: boolean;
  isPeople: boolean;
  isSchedule: boolean;
  isTransferToPayroll: boolean;
  isGroupSettings: boolean;
  url: string;
  query: ParsedQuery<string>;
}

export const LocationContext = createContext<null | ILocationContextValue>(
  null,
);

export const BaseScheduleNotificationContext = createContext({});

// todo - flesh this out more as we convert the schedule to TS
type TEventTrackingContextProps = { dayRange: number; timeframe: string };

export const EventTrackingContext = createContext<
  TEventTrackingContextProps | Record<string, never>
>({});

export const AppFeedbackModalContext = createContext({
  onOpenModal: () => {},
});

export const ForecastOverviewListContext = createContext({});
