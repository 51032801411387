import React, { useEffect } from 'react';

import { useLocation } from '@reach/router';

import LegacyDiv from '@shared/components/LegacyDiv';
import { separateAliasFromPath } from '@shared/containers/Login/utils';
import { parseQueryString, useNavigate } from '@shared/utils/route';

import AuthPageWrapper from '../AuthPageWrapper';
import { Button } from '../Button';
import Link from '../Link';
import EmailForm from './EmailForm';
import ProvidersPicker from './ProvidersPicker';

const randomFakeDomain =
  Math.floor(Math.random() * (1000000000 - 1000000 + 1)) + 1000000;

const isExternal = (targetUri) => {
  return !(
    targetUri.includes('/passwordLogin') || targetUri.includes('/multiFactor')
  );
};

const LoginProviders = ({
  onEmailSubmit,
  loginTenants: { data: loginTenants, isLoading, isError, errors } = {},
  emailOrProviderId,
  onChangeEmailOrProviderId,
  region,
  onRegionChange,
  isSuccessRegion,
  loginProviders: {
    data: { providers = [] } = {},
    isLoading: isLoadingProviders,
  } = {},
  onDomainClick,
  onFetchDomains,
}) => {
  const { search, pathname } = useLocation();
  const { token } = parseQueryString(search);
  const navigate = useNavigate();

  const shouldShowTenantsPicker =
    (loginTenants?.length > 1 && !isLoading) || pathname === '/providers';
  const shouldShowProvidersPicker = providers.length > 1 && !isLoadingProviders;

  useEffect(() => {
    if (pathname === '/providers') {
      onFetchDomains(token);
    }
  }, [pathname, token, onFetchDomains]);

  useEffect(() => {
    if (!loginTenants) return;

    if (loginTenants.length === 1) {
      return onDomainClick(loginTenants[0].domainGroupId, token);
    }
    if (loginTenants.length === 0) {
      return onDomainClick(randomFakeDomain, token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginTenants]);

  useEffect(() => {
    if (providers.length === 1) {
      const url = providers[0].targetUri;
      if (isExternal(url)) {
        window.location.href = url;
      } else {
        const { customerAlias } = separateAliasFromPath();
        navigate(`${customerAlias ? `/${customerAlias}` : ''}${url}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers]);

  return (
    <AuthPageWrapper>
      <LegacyDiv className="flex-one overflow-y-auto">
        {shouldShowTenantsPicker && !shouldShowProvidersPicker && (
          <div>
            <LegacyDiv className="flex-center margin-4-bottom">
              <h1 className="login-header padding-0">
                Select customer to login into
              </h1>
            </LegacyDiv>
            {loginTenants?.map(({ domainName, domainGroupId }) => (
              <div
                key={domainGroupId}
                className="flex flex-column border-1-bottom">
                <Button
                  color="neutral"
                  variant="text"
                  text={domainName}
                  data-test-id="providersPicker-link"
                  className="providersPicker border-bottom"
                  key={domainGroupId}
                  onClick={() => onDomainClick(domainGroupId, token)}
                />
              </div>
            ))}
          </div>
        )}

        {!shouldShowTenantsPicker && !shouldShowProvidersPicker && (
          <>
            <LegacyDiv className="flex-center margin-4-bottom">
              <h1 className="login-header padding-0">Welcome to Quinyx</h1>
            </LegacyDiv>
            <EmailForm
              onEmailSubmit={onEmailSubmit}
              isLoading={isLoading}
              email={emailOrProviderId}
              onChangeEmail={onChangeEmailOrProviderId}
              isError={isError}
              errors={errors}
              region={region}
              onRegionChange={onRegionChange}
              isSuccessRegion={isSuccessRegion}
            />
          </>
        )}
        {shouldShowProvidersPicker && (
          <>
            <LegacyDiv className="flex-center margin-4-bottom">
              <h1 className="login-header padding-0">Choose a provider</h1>
            </LegacyDiv>
            <ProvidersPicker loginProviders={providers} />
          </>
        )}
      </LegacyDiv>
      <LegacyDiv className="text-center padding-12-top">
        <span>
          <span>Please read our</span>
          <Link
            data-test-id="loginProviders__privacyPolicyLink"
            to="https://www.quinyx.com/privacy#quinyx-application"
            className="link-primary padding-1-left"
            target="_blank"
            external>
            Privacy Policy
          </Link>
        </span>
      </LegacyDiv>
    </AuthPageWrapper>
  );
};

export default LoginProviders;
