import request from 'superagent';

import { CUSTOMER_ALIAS } from '@shared/constants';
import { IFRAME_EVENT } from '@shared/constants/iframeEvents';
import { OriginalError } from '@shared/containers/hooks/api/types';
import { ResponsePromise } from '@shared/types';
import { postMessageToParent } from '@shared/utils/embedded';
import { postMessageToWebView } from '@shared/utils/webView';

import { b64UTF8Encoder } from '../../shared/api/authentication/utils';
import { patchPromiseWithAbort } from '../../shared/api/utils';
import { notify } from '../../shared/components/Notifications/utils';
import config from '../../shared/config';
import { debounce } from '../../shared/utils';

const httpRequestToken = 'love';

// We use a global agent to set default headers and update headers for all requests
// https://ladjs.github.io/superagent/#agents-for-global-state
const agent = request.agent();
agent.set('x-Requested-with', httpRequestToken);
agent.set('x-app-version', window.version);

export const HEADER_FIELD = {
  CURRENT_GROUP: 'x-current-group',
} as const;

type HeaderField = (typeof HEADER_FIELD)[keyof typeof HEADER_FIELD];

export const updateRequestHeaderField = (field: HeaderField, value: string) => {
  agent.set(field, value);
};

export const buildUrl = (url: string) => `${config.new_api}${url}`;

const throwError = (error: OriginalError) => {
  const { body } = error.response || {};

  throw { ...error, body }; // eslint-disable-line no-throw-literal
};

const notifyLoggedOut = debounce(() => notify('error.loggedOut', 'error'), 500);

export const handleError = (error: OriginalError) => {
  const customerAlias = sessionStorage.getItem(CUSTOMER_ALIAS);
  if (error.status === 401) {
    sessionStorage.clear();
    notifyLoggedOut();
    postMessageToWebView('LOGOUT');
    postMessageToParent({ event: IFRAME_EVENT.LOGOUT });
    setTimeout(() => {
      const redirectUrl = customerAlias ? `/${customerAlias}` : '/';
      window.location.assign(redirectUrl);
    }, 2000);
  }

  throwError(error);
};

export const getWithHeader = <T>(
  url: string,
  headerKey: string,
  headerValue: string,
): ResponsePromise<T> => {
  const getRequest = agent
    .get(buildUrl(url))
    .set(headerKey, headerValue)
    .withCredentials();

  const promiseForReturn = getRequest.catch(handleError);
  return patchPromiseWithAbort(getRequest, promiseForReturn);
};

export const get = <T>(url: string): ResponsePromise<T> => {
  const getRequest = agent.get(buildUrl(url)).withCredentials();
  const promiseForReturn = getRequest.catch(handleError);
  return patchPromiseWithAbort(getRequest, promiseForReturn);
};

export const put = <T>(
  url: string,
  // TODO: Payload should use type "string | Record<string, unknown>" to reflect superagent. However it will need converting some interfaces to types .
  // This is because interfaces need to declare explicitly an index signature. While type aliases implicitly inferred by TS.
  payload: any,
): ResponsePromise<T> => {
  return agent
    .put(buildUrl(url))
    .send(payload)
    .withCredentials()
    .catch(handleError);
};

export const post = <T>(url: string, payload: any): ResponsePromise<T> => {
  return agent
    .post(buildUrl(url))
    .send(payload)
    .withCredentials()
    .catch(handleError);
};

// use this method only if the EP should fetch the data without altering anything in a db (it's basically a GET request with a body)
export const postWithAbort = <T>(
  url: string,
  payload: any,
): ResponsePromise<T> => {
  const postRequest = agent.post(buildUrl(url)).send(payload).withCredentials();
  const promiseForReturn = postRequest.catch(handleError);
  return patchPromiseWithAbort(postRequest, promiseForReturn);
};

export const patch = <T>(url: string, payload: any): ResponsePromise<T> => {
  return agent
    .patch(buildUrl(url))
    .send(payload)
    .withCredentials()
    .catch(handleError);
};

export const del = <T>(url: string, payload?: any): ResponsePromise<T> => {
  return agent
    .del(buildUrl(url))
    .send(payload)
    .withCredentials()
    .catch(handleError);
};

export const auth = <T>(
  url: string,
  username: string,
  password: string,
  targetPath: string,
  context: string,
): ResponsePromise<T> => {
  return (
    agent
      .get(buildUrl(url))
      // @ts-expect-error - _auth is not part of superagent public api, but it does exist as private method on RequestBase, which allows to pass custom encoder
      ._auth(username, password, { type: 'basic' }, b64UTF8Encoder)
      .query({ context, 'target-path': targetPath })
      .withCredentials()
      .catch(throwError)
  );
};
export const authWithDomain = <T>(
  url: string,
  username: string,
  password: string,
  domainGroupId: string,
  targetPath: string,
  context: string,
): ResponsePromise<T> => {
  return (
    agent
      .get(buildUrl(url))
      // @ts-expect-error - _auth is not part of superagent public api, but it does exist as private method on RequestBase, which allows to pass custom encoder
      ._auth(username, password, { type: 'basic' }, b64UTF8Encoder)
      .set('domainGroupId', domainGroupId)
      .query({ context, 'target-path': targetPath })
      .withCredentials()
      .catch(throwError)
  );
};

export const authWithDomainPost = <T>(
  url: string,
  username: string,
  password: string,
  domainGroupId: string,
  targetPath: string,
  context: string,
  phoneNumber: string,
): ResponsePromise<T> => {
  return (
    agent
      .post(buildUrl(url))
      .send({ phoneNumber })
      // @ts-expect-error - _auth is not part of superagent public api, but it does exist as private method on RequestBase, which allows to pass custom encoder
      ._auth(username, password, { type: 'basic' }, b64UTF8Encoder)
      .set('domainGroupId', domainGroupId)
      .query({ context, 'target-path': targetPath })
      .withCredentials()
      .catch(throwError)
  );
};

export const authPost = <T>(
  url: string,
  username: string,
  password: string,
  targetUri: string,
  context: string,
): ResponsePromise<T> => {
  return (
    agent
      .post(buildUrl(url))
      // @ts-expect-error - _auth is not part of superagent public api, but it does exist as private method on RequestBase, which allows to pass custom encoder
      ._auth(username, password, { type: 'basic' }, b64UTF8Encoder)
      .query({ targetUri, context })
      .withCredentials()
      .catch(throwError)
  );
};

export const authCheck = (url: string, targetPath: string, context: string) => {
  return agent
    .get(buildUrl(url))
    .query({ context, 'target-path': targetPath })
    .withCredentials()
    .catch(throwError);
};
