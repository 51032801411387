import React from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';
import { separateAliasFromPath } from '@shared/containers/Login/utils';

import Link from '../../Link';

import './index.scss';

const isExternal = (targetUri) => {
  const isNavigation =
    targetUri.includes('/passwordLogin') || targetUri.includes('/multiFactor');
  return !isNavigation;
};

const ProvidersPicker = ({ loginProviders = [] }) => {
  const { customerAlias } = separateAliasFromPath();

  return (
    <LegacyDiv className="flex flex-one overflow-y-auto">
      {loginProviders.map(({ name, targetUri }) => {
        let uri = targetUri;
        const uriExternal = isExternal(targetUri);
        if (!uriExternal && customerAlias)
          uri = `/${customerAlias}${targetUri}`;

        return (
          <Link
            data-test-id="providersPicker-link"
            className="providersPicker"
            key={uri}
            external={uriExternal}
            to={decodeURIComponent(uri || '')}>
            {name}
          </Link>
        );
      })}
    </LegacyDiv>
  );
};

export default ProvidersPicker;
